<script lang="ts">
</script>

<div>
  <h1>Hi there 👋</h1>
  <h2>I'm Andree Yosua</h2>
  <h3>Frontend Developer</h3>
  <h4>at S21 Micropiranti</h4>
  <h5>
    Checkout my github <a href="https://github.com/SuperPenguin">@SuperPenguin</a>
  </h5>
  <h6>I like penguin 🐧</h6>
</div>

<style>
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
</style>
